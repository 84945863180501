$METALLIC: hsl(0,0%,78%);

* {box-sizing: border-box;}
BODY {
  margin: 0;
  padding: 0;
  font-family: arial, sans-serif;
}

MAIN {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  // By Vanesa camaño - Own work, CC BY-SA 3.0, https://commons.wikimedia.org/w/index.php?curid=20284104
  background-image: url("bg.jpg");
  background-size: cover;

  text-align: center;
  display: flex;
  flex-flow: column;
  justify-content: space-between;
  HEADER {
    flex: 0 0 auto;
    border-bottom: 1px solid white;
    background-image: linear-gradient(180deg, #8DF 0%, #CEF 100%);
    H1 {
      width: 100%;
      font-size: 1.5em;
    }
  }
  ARTICLE {
    flex: 0 1 auto;
    overflow: scroll;
    H3 {
      font-size: 1.5em;
      margin-bottom: 0.5em;
      text-shadow:
              $METALLIC 2px 2px 2px,
              $METALLIC -2px 2px 2px,
              $METALLIC 2px -2px 2px,
              $METALLIC -2px -2px 2px,
    ;
    }
    IMG {
      max-height: 80vh;
    }
  }
  ARTICLE.blank {
    background: rgba(255, 255, 255, 0.75);
    border-radius: 10px;
    margin: 1em;
    padding: 1em;
  }
  FOOTER {
    flex: 0 0 auto;
    display: flex;
    border-top: 1px solid black;
    background: $METALLIC;
    A {
      font-size: 1.5em;
      flex: 1 1 50%;
      border: 1px solid black;
      margin: 2vw;
      padding: 1vw;
      border-radius: 1vw;
    }
  }
}


.ok {color: black;}
.warning {color: orange;}
.error {color: red;}

INPUT {
  width: 90%;
  font-size: 1em;
}

A.button {
  border-radius: 3vw;
  border: 4px solid #222;
  color: black;
  text-decoration: none;
  font-weight: bold;
  text-shadow: #DDD 0px 2px 1px;
  box-shadow:
          inset #DDD 2px 2px 0px,
          inset #AAA -2px -2px 0px,
          #DDD 2px 2px 1px,
          #AAA -2px -2px 1px,
          #AAA 2px -2px 1px,
          #AAA -2px 2px 1px;
  background-image: linear-gradient(180deg, hsl(0,0%,78%)  0%,
          hsl(0,0%,90%) 47%,
          hsl(0,0%,78%) 53%,
          hsl(0,0%,70%)100%);
}
A.button:active { // :hover
  //color: hsl(210, 100%, 40%);
  //text-shadow: hsla(210,100%,20%) 0 0 4px;
  box-shadow:
          inset #AAA 2px 2px 0px,
          inset #DDD -2px -2px 0px,
          #DDD 2px 2px 1px,
          #AAA -2px -2px 1px,
          #AAA 2px -2px 1px,
          #AAA -2px 2px 1px;
}

NAV {
  //H3 {
    // position: sticky;
    // top: 2em;
  //}
  SECTION {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    A {
      display: inline-flex;
      width: 45vw;
      height: 15vw;
      margin: 1vw;
      flex-flow: column;
      justify-content: center;
      DIV {
        flex: 0 0 auto;
      }
    }
  }
}

TABLE.settings {
  width: 100%;
  TD:first-child {
    text-align: right;
    width: 15%;
  }
  TD:last-child {
    width: 15%;
  }
  INPUT {
    width: 100%;
    margin-top: 8px;
  }
}

TABLE.phonetic {
  margin: auto;
  TD {
    width: 50%;
  }
  TABLE {
    TD:first-child,
    TD:nth-child(2) {
      width: 1%;
      text-align: right;
    }
    TD:last-child {
      text-align: left;
    }
  }
}

TABLE.vhf {
  margin: auto;
  TH {
    text-align: center;
  }
  TD:first-child,
  TD:nth-child(2) {
    text-align: right;
  }
  TD:last-child {
    text-align: left;
  }
  .duplex {
    font-style: italic;
  }
  .channel::after {
    content: ', ';
  }
  .channel:last-child::after {
    content: '';
  }
}

// .donate {}

/*
$DARK_BG: #333;
$DARK_FG: #DDD;

@media (prefers-color-scheme: dark) {
  BODY {
    background: $DARK_BG;
    color: $DARK_FG;
  }
  .ok {color: $DARK_FG;}
  A {
    background: $DARK_BG;
  }
  MAIN {
    background: #111;
    HEADER {
      background: $DARK_BG;
      border-color: $DARK_FG;
    }
    ARTICLE {
      NAV SECTION A {
        border-color: $DARK_FG;
      }
    }
    FOOTER {
      background: $DARK_BG;
      border-top-color: $DARK_FG;
      A {
        border-color: $DARK_FG;
      }
    }
  }
}
*/

///////////////////////////////////////////////////////////////////////
// Don't put stuff in iPhone's weird rounded corners

MAIN {
  HEADER {
    padding-top: env(safe-area-inset-top);
    padding-left: env(safe-area-inset-left);
    padding-right: env(safe-area-inset-right);
  }
  ARTICLE {
    padding-left: env(safe-area-inset-left);
    padding-right: env(safe-area-inset-right);
  }
  FOOTER {
    padding-left: env(safe-area-inset-left);
    padding-right: env(safe-area-inset-right);
    padding-bottom: env(safe-area-inset-bottom);
  }
}
